import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsRoutingModule } from './components-routing.module';
import { ComponentsComponent } from './components.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { HorizontalContactComponent } from './horizontal-contact/horizontal-contact.component';

@NgModule({
  declarations: [ComponentsComponent, NavbarComponent, ContactModalComponent, SiteFooterComponent, AnalyticsComponent, HorizontalContactComponent],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    NgbModule,
    AngularMultiSelectModule,
    FormsModule, ReactiveFormsModule,
    NgbDatepickerModule, NgbTimepickerModule
  ],
  exports: [NavbarComponent, SiteFooterComponent, AnalyticsComponent]
})
export class ComponentsModule { }
