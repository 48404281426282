import { AccountConfigModule } from '@abp/ng.account.config';
import { CoreModule } from '@abp/ng.core';
import { IdentityConfigModule } from '@abp/ng.identity.config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management.config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management.config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ComponentsModule } from './components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import { HttpClientModule } from '@angular/common/http';

const LOGGERS = [NgxsLoggerPluginModule.forRoot({ disabled: false })];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemeSharedModule.forRoot(),
    NgxsModule.forRoot(),
    ...(environment.production ? [] : LOGGERS),
    ComponentsModule,
    NgbModule,
    FormsModule,
    RouterModule,
    HttpClientModule, 
    //CoreModule.forRoot({
    //      environment,
    //  }),

    //AccountConfigModule.forRoot({ redirectUrl: '/' }),
    //IdentityConfigModule,
    //TenantManagementConfigModule,
    //SettingManagementConfigModule,

    //SharedModule,

  ],
  providers: [CookieService],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  exports: [ComponentsModule]
})
export class AppModule {}
