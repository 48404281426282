import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DirtyLead } from '../models/leads-interface';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  baseUrl = "https://ec2-13-244-106-51.af-south-1.compute.amazonaws.com:44370/api/app/";

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    'key': 'x-api-key',
    'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x'});

  constructor(private httpClient: HttpClient) { }

  postlead(lead: DirtyLead) : Observable<any> {
    return this.httpClient.post(this.baseUrl + 'dirtyLead', lead, {headers: this.headers});
  }
}
