<ng-template #Login let-c="close" let-d="dismiss">
    <div class="modal-header modal-register no-border-header text-center">
        <button type="button" class="close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        <h6 class="text-muted">Welcome</h6>
        <h3 class="modal-title text-center">Paper Kit</h3>
        <p>Log in to your account</p>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Email</label>
            <input type="text" value="" placeholder="Email" class="form-control" />
        </div>
        <div class="form-group">
            <label>Password</label>
            <input type="password" value="" placeholder="Password" class="form-control" />
        </div>
        <button class="btn btn-block btn-round">Log in</button>
    </div>
    <div class="modal-register modal-footer no-border-footer">
        <span class="text-muted text-center">Looking <a>create an account</a> ?</span>
    </div>
</ng-template>