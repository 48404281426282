import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { HostListener } from '@angular/core';
import { LeadsService } from 'src/app/services/leads.service';
import { DirtyLead, LeadConsts, LeadPayload } from '../../models/leads-interface';
import Swal from 'sweetalert2'
import { retry, catchError } from 'rxjs/operators';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

    showCustomContact = false;
    cookiePolicyAccepted: boolean;
    closeResult: string;
    @Input() transparent = false;
    atBottomOfPage: boolean;
    modalRef;

    private toggleButton: any;
    private sidebarVisible: boolean;
    contactMethodsList = [
        { "id": 1, "itemName": "E-mail" },
        { "id": 2, "itemName": "Call Me" },
        /*{ "id": 3, "itemName": "WhatsApp" },*/
    ];
    selectedContactMethods = [];
    dropdownSettings = {
        singleSelection: true,
        text: "Select One",
        selectAllText: '',
        unSelectAllText: '',
        enableSearchFilter: false,
        classes: '',
        lazyLoading: false
    };

    selectedContactId = 0;

    data: Date = new Date();
    date: { year: number, month: number };
    dateModel: NgbDateStruct = { day: this.data.getUTCDay(), month: this.data.getUTCMonth(), year: this.data.getUTCFullYear() };

    popupContactForm: FormGroup;
    bottomContactForm: FormGroup;
    leadPayload: LeadPayload;

    contactTimeType = 'any-time';
    contactTime: any;

    constructor(public location: Location,
        private element: ElementRef,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private cookieService: CookieService,
        private leadService: LeadsService) {

        this.sidebarVisible = false;
        this.popupContactForm = this.fb.group({
            startDate: [new Date()],
            startTime: [null],
        });

        this.cookiePolicyAccepted = this.cookieService.get('debt-corporation-cookie-policy-accepted') === 'Y';
    }

    get isAtBottomOfPage() {
        return this.atBottomOfPage;
    }
    get isCallSelected() {
        return this.selectedContactId === 2;
    }

    get isEmailSelected() {
        return this.selectedContactId === 1;
    }

    get isWhatsAppSelected() {
        return this.selectedContactId === 3;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.atBottomOfPage = false;
        this.leadPayload = {
            contactMethod: 0,
            contactNumber: '',
            emailAddress: '',
            message: '',
            name: '',
            preferredContactTime: 0,
            contactTimeHour: 0,
            contactTimeMinute: 0,
        };
    }

    @HostListener('window:scroll', ['$event']) // <- Add scroll listener to window
    scrolled(event: any): void {
        this.atBottomOfPage = this.isUserNearBottom();
    }

    private isUserNearBottom(): boolean {
        const threshold = 50;
        const position = window.scrollY + window.innerHeight; // <- Measure position differently
        const height = document.body.scrollHeight; // <- Measure height differently
        return position > height - threshold;
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');
        html.classList.add('bg-info');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
        html.classList.remove('bg-info');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if (titlee === '/home') {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '/documentation') {
            return true;
        }
        else {
            return false;
        }
    }

    showContactModal(content, type) {
        if (type === 'sm') {
            console.log('aici');
            this.modalRef = this.modalService.open(content, { size: 'sm' });
            this.modalRef.result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else {
            this.modalRef = this.modalService.open(content);
            this.modalRef.result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    agreeCookiePolicy() {
        this.cookieService.set('debt-corporation-cookie-policy-accepted', 'Y');
        this.cookiePolicyAccepted = true;
    }

    closeContactModal() {
        this.modalRef.close();
    }

    handleError(error) {
        alert('error');
    }

    postLead() {
        
        let lead: DirtyLead = {
            leadDateTime: new Date(),
            leadPayload: JSON.stringify(this.leadPayload),
            originatorSoftwareKey: LeadConsts.SOFTWARE_KEY,
            status: LeadConsts.INITIAL_STATUS
        };

        this.leadService.postlead(lead).subscribe(response => {
            Swal.fire({
                icon: 'info',
                title: 'Success',
                text: 'The information you provided was successfuly sent to us. Someone will be in contact with you shortly.'
            })
        }, (error: Response) => {

            console.log(`Error code: ${error.status}`);
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                text: 'There was an error submitting your contact request. We have made a note of this on our end. Please contact us on the phone number or e-mail address if you have an urgent message.',
            })
        }
        );
        /*
            .pipe(
                catchError(() => {alert('error')})
            );
            /*
            Swal.fire({ 
                icon: 'info',
                title: 'Success',
                text: 'The information you provided was successfuly sent to us. Someone will be in contact with you shortly.'
            })*/

        this.closeContactModal();
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    onItemSelect(item: any) {
        console.log(item);
        console.log(this.selectedContactMethods);
        this.selectedContactId = item.id;
        this.leadPayload.contactMethod = item.id;
    }
    OnItemDeSelect(item: any) {
        console.log(item);
        console.log(this.selectedContactMethods);
    }
    onSelectAll(items: any) {
        console.log(items);
    }
    onDeSelectAll(items: any) {
        console.log(items);
    }

    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: { month: number }) {
        return date.month !== current.month;
    }

    emailAddressChange(value: string){
        this.leadPayload.emailAddress = value;
    }

    messageChange(value : string){
        this.leadPayload.message = value;
    }

    contactNumberChange(value : string){
        this.leadPayload.contactNumber = value;
    }

    preferredContactTimeContactAtChanged(value){
        this.leadPayload.preferredContactTime = 1;
    }

    preferredContactTimeContactAnyTimeChanged(value){
        this.leadPayload.preferredContactTime = 0;
    }

    preferredContactTimeContactBeforeChanged(value){
        this.leadPayload.preferredContactTime = 2;
    }

    preferredContactTimeContactAfterChanged(value){
        this.leadPayload.preferredContactTime = 3;
    }

    preferredContactTimeChanged(value){
        this.leadPayload.contactTimeHour = value.hour;
        this.leadPayload.contactTimeMinute = value.minute;
    }
}
