<footer class="footer footer-big footer-black" style="margin-top: -30px;margin-bottom: -20px;">
  <div class="container">

    <div class="col-md-9 ml-auto mr-auto col-sm-9 col-12">

      <div class="row">
        <div class="col-md-3">
          © 2020 Debt Corporation
          <br />
          NCRDC 3196
          <br />
          <div class="links">
            <ul>
              <li>
                <a [routerLink]="['/pages/terms']">Terms</a>
              </li>
              <li>
                <a [routerLink]="['/pages/privacy']">Privacy</a>
              </li>
              <li>
                <a [routerLink]="['/pages/cookies']">Cookie Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4">
          <i class="fas fa-phone text-white" style="margin-right: 10px;"></i> +27 12 881 1390
          <br />
          <i class="fas fa-envelope text-white" style="margin-right: 10px;"></i><a href="mailto:info@debtcorporation.co.za">info@debtcorporation.co.za</a>
        </div>
        <div class="col-md-3">
          <a href="https://www.facebook.com/debtcorp/?ref=aymt_homepage_panel&eid=ARD-LxTRbZtx80U6JtGcy6mQPnlkLgDvYQ_2nQxmtni3VaA-UkZiXgi0oS6ITxek7ezJQxonIuTh3L8j" class="btn btn-facebook-bg facebook-sharrre btn-round" rel="tooltip" title="Share!">
            <i class="fa fa-facebook-square"></i> Facebook
          </a>
        </div>
        <div class="col-md-2">
          NO. 8, First
          Menlo Park Street
          Pretoria
          0081
        </div>
      </div>

    </div>
  </div>
</footer>
