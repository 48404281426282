import { Time } from '@angular/common';

export class LeadConsts {
    public static SOFTWARE_KEY = 'D66CF7E9-EB43-418A-8E8D-998C0D3EC5B0';
    public static INITIAL_STATUS = '7364F2A9-AEA4-4C1F-A009-A4775DFA2461';
    public static CONTACT_PHONE = 0;
    public static CONTACT_EMAIL = 1;
    public static CONTACT_ANYTIME = 0;
    public static CONTACT_AT = 1;
    public static CONTACT_BEFORE = 2;
    public static CONTACT_AFTER = 3;
}

export interface DirtyLead {
    originatorSoftwareKey: string;
    leadDateTime: Date;
    status: string;
    leadPayload: string;
}

export interface LeadPayload {
    name: string;
    contactMethod: number;
    emailAddress: string;
    message: string;
    contactNumber: string;
    preferredContactTime: number;
    contactTimeHour: number;
    contactTimeMinute: number;
}