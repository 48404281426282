<nav class="navbar navbar-expand-lg fixed-top nav-down bg-navbar navbar-dark" style="height: 130px;"
    [ngClass]="{'navbar-transparent' : transparent}" color-on-scroll="500">
    <div class="container">
        <div class="navbar-translate">
            <div class="navbar-header">
                <a class="navbar-brand" [routerLink]="['']"><img src="assets/img/DCLogoOrangeTransparentBG.png"
                        height="120px" alt="" title=""></a>
            </div>
            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse"
                data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-icon bar1"></span>
                <span class="navbar-toggler-icon bar2"></span>
                <span class="navbar-toggler-icon bar3"></span>
            </button>
        </div>
        <div class="collapse navbar-collapse bg-navbar">
            <ul class="navbar-nav ml-auto bg-navbar">
                <li class="nav-item">
                    <a [routerLink]="['/howitworks']">
                        <div class="nav-link" style="font-size: 18px;">How it works</div>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/faq']">
                        <div class="nav-link" style="font-size: 18px;">FAQ</div>
                    </a>
                </li>
                <li ngbDropdown class="nav-item">
                    <a href="https://www.facebook.com/debtcorp/?ref=aymt_homepage_panel&eid=ARD-LxTRbZtx80U6JtGcy6mQPnlkLgDvYQ_2nQxmtni3VaA-UkZiXgi0oS6ITxek7ezJQxonIuTh3L8j" target="_blank">
                        <div class="nav-link" style="font-size: 18px;">Blog</div>
                    </a>
                </li>
                <li ngbDropdown class="nav-item">
                    <a href="https://sacoronavirus.co.za/" target="_blank">
                        <div class="nav-link" style="font-size: 18px;">COVID-19</div>
                    </a>
                </li>
                <li ngbDropdown class="nav-item" style="font-size: 18px;">
                    <a class="btn btn-round btn-danger text-white" (click)="showContactModal(Login, '')"
                        style="font-size: 18px;">
                        Contact
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="cookie-footer" id="cookie-footer" *ngIf="!cookiePolicyAccepted">
        <label style="color: #080808;font-size: large;">We use cookies to give you the best experience. Read our
        </label>
        <a [routerLink]="['/pages/cookies']" style="font-size: large;font-weight: bold;"><b> cookie policy</b></a>
        <a class="btn btn-round btn-danger text-white" style="font-size: 14px;margin-left: 15px;"
            (click)="agreeCookiePolicy()">Agree</a>
    </div>

    <div class="contact-footer" id="contact-footer" *ngIf="isAtBottomOfPage">
        <div class="row" style="color: #080808;">
            <div class="col-12">
                Have any more questions or require more information? Just want to ask some questions to see if you qualify?

                <a class="btn btn-round btn-danger text-white" (click)="showContactModal(Login, '')"
                    style="font-size: 18px;">
                    Contact Us
                </a>

                and we'll happily assit
            </div>
        </div>
    </div>
</nav>

<ng-template #Login let-c="close" let-d="dismiss">
    <div class="modal-header modal-register no-border-header text-center">
        <button type="button" class="close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h6 class="text-muted">Call us on</h6>
        <h2 class="modal-title text-center subtitle-orange"><strong>+27 12 881 1390</strong></h2>

        <h6 class="text-muted">or mail us at</h6>
        <h3 class="modal-title text-center subtitle-orange"><strong><a
                    href="mailto:info@debtcorporation.co.za">info@debtcorporation.co.za</a></strong></h3>

        <h6 *ngIf="showCustomContact" class="modal-title text-center">alternatively, give us your detail and</h6>

        <h6 *ngIf="showCustomContact" class="modal-title text-center">we'll contact you</h6>
    </div>

    <div class="modal-body" *ngIf="showCustomContact">
        <div class="form-group">
            <label>Name</label>
            <input type="text" value="" placeholder="Name" class="form-control" />
        </div>
        <div class="form-group">
            <label>Preferred Contact Method</label>
            <angular2-multiselect [data]="contactMethodsList" [(ngModel)]="selectedContactMethods"
                [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
            </angular2-multiselect>
        </div>
        <div class="form-group" *ngIf="isEmailSelected">
            <label>E-mail Address</label>
            <input type="text" value="" placeholder="Email Address" class="form-control" (input)="emailAddressChange($event.target.value);" />
        </div>
        <div class="form-group" *ngIf="isEmailSelected">
            <label>Message</label>
            <textarea class="form-control border-input" placeholder="Leave us a message here..." rows="3" (input)="messageChange($event.target.value)"></textarea>
        </div>
        <div class="form-group" *ngIf="isCallSelected || isEmailSelected">
            <label>Contact Number</label>
            <input type="text" value="" placeholder="Contact Number" class="form-control" (input)="contactNumberChange($event.target.value)"/>
        </div>
        <div class="form-group" *ngIf="isWhatsAppSelected">
            <label>Mobile Number</label>
            <input type="text" value="" placeholder="Mobile Number" class="form-control" />
        </div>
        <div class="form-group" *ngIf="isCallSelected">
            <label>Preferred Contact Time</label>
            <div class="row">
                <div class="col-5">
                    <div class="form-check-radio">
                        <label class="form-check-label">
                            <input class="form-check-input" checked type="radio" name="contact-option" (change)="preferredContactTimeContactAnyTimeChanged($event)"
                                id="input-contact-aanytime" value="any-time" checked [(ngModel)]="contactTimeType">
                            Any time
                            <span class="form-check-sign"></span>
                        </label>
                    </div>
                    <div class="form-check-radio">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="contact-option" (change)="preferredContactTimeContactAtChanged($event)"
                            id="input-contact-at" value="contact-at" [(ngModel)]="contactTimeType">
                            Call me at
                            <span class="form-check-sign"></span>
                        </label>
                    </div>
                    <div class="form-check-radio">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="contact-option" (change)="preferredContactTimeContactBeforeChanged($event)"
                            id="input-contact-before" value="contact-before" [(ngModel)]="contactTimeType">
                            Before
                            <span class="form-check-sign"></span>
                        </label>
                    </div>
                    <div class="form-check-radio">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" name="contact-option" (change)="preferredContactTimeContactAfterChanged($event)"
                            id="input-contact-after" value="contact-after" [(ngModel)]="contactTimeType">
                            After
                            <span class="form-check-sign"></span>
                        </label>
                    </div>
                </div>
                
                <div class="col-7" style="margin-top: 20px">
                    <ngb-timepicker [ngModel]="contactTime" meridian="meridian" (ngModelChange)="preferredContactTimeChanged($event)"></ngb-timepicker>
                </div>


            </div>


        </div>

        <button class="btn btn-block btn-round btn-danger" (click)="postLead()">Submit</button>

    </div>
</ng-template>
